// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// MSO packages
import '@mod-mijnsiteonline/components/slideshow/slideshow';
import '@mod-mijnsiteonline/components/slider/slider';

// main CSS
import './vrederick.scss';
import 'font-awesome/css/font-awesome.min.css';

// pages
import './shared/pages';


// components & packages
import './mobilemenu/mobilemenu';
import './smartmenus/smartmenus';
import './hamburgermenu/hamburgermenu';
import './sidebarmenu/sidebarmenu';
import './widgets/widgets';


import './slidemenu/slidemenu';

// template specific JS files
window.$ = require('jquery');
window.jQuery = $;
// require('./web/js/jquery.poptrox.js');
// require('./web/js/jquery.scrolly.min.js');
// require('./web/js/jquery.scrollex.min.js');
// window.skel = require('./web/js/skel.min.js');
// require('./web/js/util.js');
import './web/js/main';

import * as dompack from 'dompack';

let header;
let siteLogo;
let isHomepage;
let alwaysChange;

dompack.onDomReady(() => {
  header = $("#header");
  siteLogo = $("#sitelogo");
  isHomepage = $('html').hasClass("home");
  alwaysChange = $('html').hasClass("acl");

  $(window).scroll(handleScroll);

  handleScroll();
});

function handleScroll() {
  let scrollTop = $(window).scrollTop();
  if (scrollTop > 75 && !header.hasClass("scroll")) {
      header.addClass('scroll');

    if(isHomepage || alwaysChange)
      changeLogo(true);

  } else if(scrollTop <= 75 && header.hasClass("scroll") ) {
      header.removeClass('scroll');

    if(isHomepage || alwaysChange);
      changeLogo(false);
  }
}

function changeLogo(scrolled) {
  if(siteLogo.hasClass("onscrollchange")) {
    siteLogo.attr("src", scrolled ? siteLogo.data("mainlogo") : siteLogo.data("prescroll"));
  }
}

