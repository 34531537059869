import '../../../vendor/smartmenus-1.1.0/sm-core-css.css';
import '../../../vendor/smartmenus-1.1.0/sm-clean.css';
import './slidemenu.scss';

import $ from 'jquery';

import * as dompack from 'dompack';

dompack.onDomReady(() => {

  var slide = $("#slide-menu");
  var mSlide = $("#mobile-slide-menu");

  resetMobileMenuPos();

  $(window).on("resize", function() {
    resetMobileMenuPos();
  })

  // Language selection list
  $('.langselected').on('mouseover', function(event){
    if($('.langselected').hasClass('active')){
      $('.langselected').removeClass("active");
      $('.langlist').css("display", "none");
    }else{
      $('.langselected').addClass("active");
      $('.langlist').css("display", "block");
    }
  });

  $("#hamburger").click(function()
  {
    $("#hamburger").hasClass("is-active") ? $("#hamburger").removeClass("is-active") : $("#hamburger").addClass("is-active");
    // the mobile slide menu doesnt have any styling when it isnt less than/equal to X client width
    if (mSlide.css("overflow") !== "scroll")
    {
      toggleSlideMenu();
    }
    else
    {
      if (slide.hasClass("active visible"))
      {
        slide.removeClass("active visible");
      }
      toggleMobileMenu();
    }
  });

  $(".submenu-icon").click(function(){
    toggleSubMenu(this);
  });



  function toggleSlideMenu()
  {
    if(slide.hasClass("active")) {
      slide.removeClass("active visible");
    } else {
      slide.addClass("active");

      setTimeout(function(){
        slide.addClass("visible");
      }, 500);
    }
  }

  function toggleMobileMenu()
  {
    if(mSlide.hasClass("active"))
    {
      mSlide.removeClass("active").slideUp();
    }
    else
    {
      mSlide.addClass("active").slideDown();
    }
  }

  function toggleSubMenu(obj)
  {
    var list = $(obj).parent().parent().find('ul').get(0);
    var li = $(obj).parent().parent();
    $(list).hasClass("active") ? $(list).removeClass("active") : $(list).addClass("active");

    if(!$(list).hasClass("active")) {
      $(list).slideUp();
      $(li).removeClass("opened");
    } else {
      $(list).slideDown();
      $(li).addClass("opened");
    }
  }

  function resetMobileMenuPos()
  {
    var posFromTop = $("#header").outerHeight();

    if($(".contactheader") !== undefined)
    {
      posFromTop += $(".contactheader").outerHeight();
    }

    mSlide.css("height", document.documentElement.clientHeight - posFromTop).css("top", posFromTop);
  }
});

