import '../../../vendor/smartmenus-1.1.0/sm-core-css.css';
import '../../../vendor/smartmenus-1.1.0/sm-clean.css';
import './smartmenus.scss';

import $ from 'jquery';
require('../../../vendor/smartmenus-1.1.0/jquery.smartmenus.min.js');

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  $('#main-menu').smartmenus({
    mainMenuSubOffsetX: -1,
    mainMenuSubOffsetY: 4,
    subMenusSubOffsetX: 6,
    subMenusSubOffsetY: -6
  });

  // Language selection list
  $('#langselected').on('mouseover', function(event){
    if($('#langselected').hasClass('active')){
      $('#langselected').removeClass("active");
      $('#langlist').css("display", "none");
    }else{
      $('#langselected').addClass("active");
      $('#langlist').css("display", "block");
    }
  });
});
