import './sidebarmenu.scss';

import $ from 'jquery';

import * as dompack from 'dompack';

dompack.onDomReady(() => {

	var	$this = $('#sidebarnav');

	for(var i = 0; i < $this.find(".opener").length; i++){
		var arrow = "fa fa-caret-down";
		if($this.find(".opener").get(i).classList.contains('current')) {
			$($this.find(".opener").get(i)).find('ul').get(0).classList.add("active");
			$($($this.find(".opener").get(i)).find('ul').get(0)).slideDown();
			arrow = "fa fa-caret-up";
		}

		var span = $("<span>");
		span.addClass(arrow);
		span.addClass("span");
		span.prependTo($this.find(".opener")[i]);
		$($this.find(".opener").get(i)).find('ul').get(0).style.position = 'relative';
	}

	$('#sidebarnav').on('click', 'span', function(e){
		if($(e.target).parent().find('ul').get(0).classList.contains("active")){
			$($(e.target).parent().find('ul').get(0)).slideUp();
	  		$(e.target).parent().find('ul').get(0).classList.remove("active");
	  		$(e.target).removeClass("fa-caret-up");
	  		$(e.target).addClass("fa-caret-down");
		}else{
	  		$($(e.target).parent().find('ul').get(0)).slideDown();
		  	$(e.target).parent().find('ul').get(0).classList.add("active");
		  	$(e.target).removeClass("fa-caret-down");
		  	$(e.target).addClass("fa-caret-up");
		  }
	});
});